import React from "react";
import { BlogArticleMeta } from "../../@types/blog.types";
import ArticleHeader from "./ArticleHeader";

const Article: React.FC<{
    meta: BlogArticleMeta
    children: React.ReactNode
}> = ({ children, meta }) => {
    return (
        <article className="blogArticle page">
            <ArticleHeader MetaData={meta} />
            <div className="content">
                {children}
            </div>
        </article >
    );
}

export default Article;