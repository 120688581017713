import Graphic from '../../assets/waterTransition.png';
import GraphicMin from '../../assets/waterTransitionMin.png';

const WaterTransition = () => {
    return (
        <div className="waterTransition">
            <picture>
                <source srcSet={Graphic} media="(min-width: 768px)" />
                <img src={GraphicMin} alt="" className="graphic" />
            </picture>
        </div>
    );
}

export default WaterTransition;