import React from "react";
import Mika from "../../assets/personen/mika.png";
import Ferdi from "../../assets/personen/ferdi.png";
import Phil from "../../assets/personen/phil.png";
import Niko from "../../assets/personen/niko.jpeg";
import Momo from "../../assets/personen/momo.png";
import BgLines from "../../assets/organisatorenBgLines.svg";

const Organisation = () => {
    return (
        <section className="organisation">
            <div className="organisatoren">
                <h2>Die Organisatoren</h2>
                <div className="personen">
                    <img src={BgLines} alt="Background" className="bg" />
                    <div className="col">
                        <Organisator name="Mika Egelhaaf" img={Mika} classname="mika" />
                        <Organisator name="Moritz Kaminski" img={Momo} classname="momo" />
                    </div>
                    <div className="col">
                        <Organisator name="Nikolaus Kaminski" img={Niko} classname="niko" />
                    </div>
                    <div className="col">
                        <Organisator name="Ferdinand Duschka" img={Ferdi} classname="ferdi" />
                        <Organisator name="Philipp Kaminski" img={Phil} classname="phil" />
                    </div>
                </div>
            </div>
            <div className="zielgruppe">
                <h2>Die Zielgruppe</h2>
                <p>Um den Spaß-Faktor zu erhöhen und gleichzeitig mehr Zeit mit gleichgesinnten Freunden zu Verbringen, besteht die Grzppe vermehrt aus Jugendlichen. Das heißt aber nicht, dass jüngere oder ältere Naturfreunde ausgeschlossen werden!<br /><br />
                    Jeder, der Spaß, Motivation und Freude an der Natur mitbringt ist bei uns immer herzlich willkommen! Auch der Wille zu Arbeiten sollte nicht zu kurz kommen!<br /><br />
                    Generell kann man sich auf einen netten Haufen von Jugendlichen einstellen, die immer versuchen, die eigentlich harte Arbeit so lustig wie nur möglich zu Gestalten.<br /><br />
                    Immer wieder sind aber auch Eltern oder externe Erwachsene und Kinder eingeladen bei unseren Exkursionen mitzumachen!
                </p>
            </div>
        </section>
    );
}

const Organisator: React.FC<{
    img: string;
    name: string;
    classname: string;
}> = ({ img, name, classname }) => {
    return (
        <div className={`person ${classname}`}>
            <img src={img} className="pb" alt={name} />
            <h3 className="name">{name}</h3>
        </div>
    );
}

export default Organisation;