import TeichGraphic from "../../assets/teich.svg";
import StuffGraphic from "../../assets/stuff.svg";
import HeaderStats from "./HeaderStats";
import TestImg from "../../assets/test.svg";

const Header = () => {
    return (
        <header>
            <div className="container">
                <h1>Be the solution,<br />not the pollution.</h1>
                <img src={TeichGraphic} className="teich" alt="" />
                <img src={StuffGraphic} className="stuff" alt="" />
                <HeaderStats />
                <div className="edge"></div>
            </div>
        </header>
    )
}

export default Header;