import React from "react";
import "../../styles/blog.scss";
import Header from "../../components/blog/Header";
import ArticlePreview from "../../components/blog/ArticlePreview";
import { Articles } from "./handler";
import { Helmet } from "react-helmet";

const Blog = () => {
    return (
        <div className="page blog">
            <Helmet>
                <title>Blog | IsarRanger</title>
            </Helmet>
            <Header />
            <div className="container">
                <div className="articles">
                    {Articles.map((article, index) =>
                        <ArticlePreview key={index} article={article} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Blog;