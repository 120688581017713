import React from "react";
import MapIcon from "../../assets/icons/map.svg";

const Stats = () => {
    return (
        <section className="stats">
            <div className="goLocal">
                <img src={MapIcon} alt="Map Icon" className="icon" />
                <p className="text">Da unser Motto „Go Local” ist und unser Einsatzgebiet rund um die Großhesselohe Brücke  liegt, wohnen die meisten Mitglieder in der Umgebung.</p>
            </div>
            <div className="numbers">
                <div className="number">
                    <div className="bg green"></div>
                    <p className="text">25</p>
                    <p className="description">Mitglieder</p>
                </div>
                <div className="number">
                    <div className="bg orange"></div>
                    <p className="text">12</p>
                    <p className="description">Treffen im Jahr</p>
                </div>
            </div>
        </section>
    );
}

export default Stats;