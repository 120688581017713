import { BlogArticle, BlogAuthor } from "../../@types/blog.types";

// PAGES
import WuermrangerJapanKnoeterich from "./artikel/2022/wuermranger-japan-knoeterich";
import DayOfHope2023 from "./artikel/2023/day-of-hope";
import OekologischesBildungszentrum from "./artikel/2023/oekologisches-bildungszentrum";
import AufraeumenIsarstrand from "./artikel/2023/aufraeumen-isarstrand";
import BesuchBeimImker from "./artikel/2023/besuch-beim-imker";

export const Articles: BlogArticle[] = [
    BesuchBeimImker,
    AufraeumenIsarstrand,
    OekologischesBildungszentrum,
    DayOfHope2023,
    WuermrangerJapanKnoeterich
];  