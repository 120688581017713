import React from "react";
import { Link } from "react-router-dom";
import { BlogArticle } from "../../@types/blog.types";

const ArticlePreview: React.FC<{
    article: BlogArticle
}> = ({ article }) => {
    return (
        <Link to={`/blog${article.url}`} className="articlePreview">
            <img src={article.headerImage} alt={article.title} className="headerImage" />
            <div className="meta">
                <p className="date">{article.date.toLocaleDateString("de-DE", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric"
                })}</p>
                <h3 className="title">{article.title}</h3>
                <p className="description">{article.description}</p>
            </div>
        </Link>
    );
}

export default ArticlePreview;