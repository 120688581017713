import React from "react"
import styles from "../styles/pingHeader.module.scss"

const PingHeader: React.FC<{
    text: string
}> = ({text}) => {
    return(
        <div className={styles.pingHeader}>
            <div className={styles.ping}>
                <div className={styles.inner}></div>
            </div>
            <p>{text}</p>
        </div>
    );
}

export default PingHeader;