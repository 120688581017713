import { Helmet } from "react-helmet";
import '../styles/landing.scss';
import Header from '../components/landing/Header';
import InfoSection from "../components/landing/InfoSection";
import WaterTransition from "../components/landing/WaterTransition";
import Area from "../components/landing/Area";
import WaterTransitionEnd from "../components/landing/WaterTransitionEnd";

const Landing = () => {
    return (
        <div className="landing">
            <Helmet>
                <title>IsarRanger - Root & Shoots Jugendgruppe</title>
            </Helmet>
            <div className="container">
                <Header />
                <InfoSection />
            </div>
            <WaterTransition />
            <Area />
            <WaterTransitionEnd />
        </div>
    )
}

export default Landing;