import React from "react";
import PingHeader from "../PingHeader";
import Agenda from "../landing/Agenda";

const Header = () => {
    return (
        <section className="header">
            <div className="container">
                <div className="info">
                    <PingHeader text="Unsere Projekte..." />
                    <h2 className="section-title">Unkraut, Aufräumen und Insekten</h2>
                    <p className="discription">
                        Um möglichst sinnvolle, aber auch abwechslungsreiche, Treffen zu organisieren, haben wir uns auf diese drei Arten festgelegt. Diese ermöglichen es die Fauna und Flora zu unterstützen und den von Menschen angerichteten Schaden auf ein Minimum zu reduzieren.
                    </p>
                </div>
                <Agenda expandable={false} />
            </div>
        </section>
    );
}

export default Header;