import React from "react";
import PingHeader from "../PingHeader";
import Graphic from "../../assets/aboutHeader.svg";

const Header = () => {
    return (
        <section className="header">
            <div className="container">
                <div className="info">
                    <PingHeader text="Über uns..." />
                    <h2 className="section-title">Die IsarRanger</h2>
                    <p className="discription">
                    Die IsarRanger sind eine ehrenamtlich engagierte Gruppe, die vermehrt aus Jugendlichen besteht. Organisiert sind sie im Jane Goodall Institut Deutschland. Wir pflegen die Isar durch unterschiedlichste Methoden im Gebiet der Großhesselohe-Brücke. Dabei ist uns die Balance zwischen Spaß, Lernen und Arbeit am wichtigsten.                      </p>
                </div>
                <img src={Graphic} alt="" />
            </div>
        </section>
    );
}

export default Header;