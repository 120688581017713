import React from "react";
import { Link, useLocation } from "react-router-dom";
import JaneGoodallLogo from "../assets/jane_goodall_logo.png";
import RootsShootsLogo from "../assets/roots_shoots_logo.png";

const Footer = () => {

    const noMarginPages = ['/', '/blog/2023/day-of-hope']
    let location = useLocation();
    const [hasMargin, setHasMargin] = React.useState(true);

    React.useEffect(() => {
        if (noMarginPages.includes(window.location.pathname)) {
            setHasMargin(false);
        } else {
            setHasMargin(true);
        }
    }, [location])

    return (
        <footer className="footer" style={{
            marginTop: hasMargin ? "50px" : 0
        }}>
            <div className="container">
                <div className="cols">
                    <div className="col basicLinks">
                        <Link to="/">Start</Link>
                        <Link to="/projektbeschreibung">Projekte</Link>
                        <Link to="/about">Über uns</Link>
                        {/* <Link to="/kontakt">Kontakt</Link> */}
                    </div>
                    <div className="col">
                        <Link to="/impressum">Imressum</Link>
                        <Link to="/datenschutz">Datenschutz</Link>
                    </div>
                    <div className="col images">
                        <a href="http://www.janegoodall.de/" target={"_blank"}>
                            <img src={JaneGoodallLogo} alt="Jane Godall Institut Deutschlad" />
                        </a>
                        <a href="http://www.janegoodall.de/roots-shoots/" target={"_blank"}>
                            <img src={RootsShootsLogo} alt="Roots&Shoots" />
                        </a>
                    </div>
                </div>

                <div className="copyright">
                    <p>&copy; {new Date().getFullYear()} IsarRanger - Wartung durch <a href="https://plutorix.com">Plutorix</a></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;