import React from "react";
import ArticleSection from "./DefaultArticleSection";

const MultiImageSection: React.FC<{
    images: string[]
    children: React.ReactNode
    title?: string
}> = ({ children, title, images }) => {
    return (
        <ArticleSection>
            <div className="multiImageSection">
                <div className="info">
                    {title &&
                        <h2 className="sectionTitle">{title}</h2>
                    }
                    <p>{children}</p>
                </div>
                <div className="images">
                    {images.map((image, index) =>
                        <img key={index} src={image} alt={title} />
                    )}
                </div>
            </div>
        </ArticleSection>
    );
}

export default MultiImageSection;