import React from "react";

const HeaderStats = () => {
    return (
        <div className="headerStats">
            <div className="stat">
                <h3 className="big">25 <span className="borderText">+</span></h3>
                <h3 className="title">Mitglieder</h3>
                <p className="subtitle">Aktiv Dabei</p>
                <div className="seperator" />
                <p className="description">Engargierte Jugendliche, die bei mindestens einem Treffen teilgenommen haben</p>
            </div>
            <div className="stat">
                <h3 className="big">12</h3>
                <h3 className="title">Treffen</h3>
                <p className="subtitle">pro Jahr</p>
                <div className="seperator" />
                <p className="description">Die ungefähre Anzahl organisierter Events jeglicher Art</p>
            </div>
        </div>
    );
}

export default HeaderStats;