import React from "react";
import "../../../../styles/blogArticle.scss";
import { BlogArticle, BlogArticleMeta, BlogAuthor } from "../../../../@types/blog.types";
import HeaderImage from "../../../../assets/blog/headerImages/oekologisches-bildungszentrum.jpeg";
import {
    DefaultArticleSection,
    ImageSection,
    MultiImageSection
} from "../../../../components/blog/ArticleSection";
import Article from "../../../../components/blog/Article";

const MetaData: BlogArticleMeta = {
    title: "Besuch im Ökologischen Bildungszentrum",
    date: new Date(2023, 5, 6),
    headerImage: HeaderImage,
    headerImageAlt: "Besichtigung des Bildungszentrums",
    description: "Eindrücke von unserem Besuch im Ökologischeb Bildungszenttrum",
    tags: ["Besuch", "Ökologisches Bildungszentrum"],
    url: "/2023/oekologisches-bildungszentrum",
    authors: [BlogAuthor.KAMINSKI_PHILIPP]
}

const View = () => {
    return (
        <Article meta={MetaData}>
            <DefaultArticleSection>
                <p>
                    Am 6. Juli 2023 haben wir das Ökologische Bildungszentrum München besucht. Von unseren Eindrücken, die wir dort gesammelt haben, werden wir im folgenden Blogbeitrag berichten.
                </p>
            </DefaultArticleSection>
            <ImageSection title="Was haben wir dort gemacht?" image={require("../../../../assets/blog/articles/2023/oekologisches-bildungszentrum/drone.jpg")}>
                Leider waren Bauarbeiten am Hauptgebäude zu diesem Zeitpunkt am Laufen, weshalb wir leider die Ausstellungen innerhalb des Gebäudes nicht erleben konnten. Durch die zahlreichen Angebote auf dem riesigen Gelände war dies aber kein Problem.
            </ImageSection>
            <ImageSection title="Bienen am ÖBZ" image={require("../../../../assets/blog/articles/2023/oekologisches-bildungszentrum/bienen.jpeg")}>
                Besonders gut gefallen hat uns der Teil der Ausstellung, der sich mit Bienen und ihrem Lebensraum befasst hat.
                Die Bienenweiden sahen nämlich nicht nur gut aus, sondern dienen auch den Bienen als zuverlässige Nektar-Quelle
                Das hat uns auch dazu inspiriert, ein Projekt zu planen, in dem wir einen Teil unseres Gartens zu einer Bienenweide umfunktionieren!
                Falls das gut funktioniert wollen wir diese Idee und Tipps zur Umsetzung auch mit unseren restlichen Mitgliedern teilen.
            </ImageSection>
        </Article>
    );
}

const RenderedArticle: BlogArticle = {
    ...MetaData,
    page: View
}

export default RenderedArticle; 
