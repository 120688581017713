import React from "react";
import "../styles/mitmachen.scss";
import Header from "../components/mitmachen/Header";
import Process from "../components/process/Process";
import { Helmet } from "react-helmet";


const Mitmachen: React.FC = () => {
    return (
        <div className="mitmachen">
            <Helmet>
                <title>Mitmachen | IsarRanger</title>
            </Helmet>
            <Header />
            <Process
                hilighted={false}
                title="Mitglied werden"
                tasks={[
                    {
                        title: "Formular Ausfüllen",
                        description: "Wenn du aus München und Umgebung kommst, fülle unter isar-ranger.com/anmelden dein Formular aus"
                    },
                    {
                        title: "Erstes Event mitmachen",
                        description: "Sei bei deinem ersten IsarRanger Event dabei und lerne uns hier peröhnlich kennen"
                    },
                    {
                        title: "Du bist Teil der IsarRanger!",
                        description: "Du bist jetzt offiziell Teil der IsarRanger und kannst mithelfen München und seine Isar ein Stück besser zu machen"
                    }
                ]}
            />
        </div>
    );
}

export default Mitmachen;