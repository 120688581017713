import React from "react";
import PingHeader from "../PingHeader";
import Graphic from "../../assets/blogHeader.svg";

const Header = () => {
    return (
        <section className="header">
            <div className="container">
                <div className="info">
                    <PingHeader text="Der IsarRanger..." />
                    <h2 className="section-title">Blog</h2>
                    <p className="discription">
                        Hier erhälst du einen kleinen Einblick, wie unsere Events in der Umsetzung aussehen. Falls du auch auf diesem Blog erscheinen möchtest, musst du dich nur bei uns anmelden!               </p>
                </div>
                <img src={Graphic} alt="" />
            </div>
        </section>
    );
}

export default Header;