import React from "react";

export interface TaskProps {
    index?: number
    title: string
    description: string
}

export const Task: React.FC<{
    data: TaskProps
}> = ({ data }) => {
    return (
        <div className="task">
            <p className="index">{data.index || 0}</p>
            <h4 className="title">{data.title}</h4>
            <p className="descrition">{data.description}</p>
            {data.index! < 3 &&
                <div className="seperator" />
            }
        </div>
    );
}
