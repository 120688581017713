import React from "react";
import "../../../../styles/blogArticle.scss";
import { BlogArticle, BlogArticleMeta, BlogAuthor } from "../../../../@types/blog.types";
import HeaderImage from "../../../../assets/blog/headerImages/aufraeumen-isarstand.jpg";
import {
    DefaultArticleSection,
    ImageSection,
    MultiImageSection
} from "../../../../components/blog/ArticleSection";
import Article from "../../../../components/blog/Article";

const MetaData: BlogArticleMeta = {
    title: "Mini-Aufräumaktionen am Isarstrand",
    date: new Date(2023, 6, 15),
    headerImage: HeaderImage,
    headerImageAlt: "An der Isar gesammelter Müll",
    description: "Bericht über unsere Mini-Aufräumaktionen am Isarstrand",
    tags: ["Isar", "Strand", "Aufräumen"],
    url: "/2023/aufraeumen-isarstrand",
    authors: [BlogAuthor.KAMINSKI_PHILIPP]
}

const View = () => {
    return (
        <Article meta={MetaData}>
            <DefaultArticleSection>
                <p>
                    Zwischen August 2022 und Mai 2023 haben wir neben dem Abitur noch einige Mini-Aufräumaktionen durchgeführt. Diese waren meist, nachdem wir selbst an der Isar feiern waren und dann noch im Umkreis Müll aufgehoben haben.
                    Außerdem konnten wir dadurch schonmal für unsere erste große Aufräumaktion üben und einige Orte heraussuchen, an denen immer viel Müll liegt.
                    Den Müll bringen wir nach dem Einsammeln an designierte Müll-Stellen, die die Stadt extra entlang der Isar platziert.
                </p>
            </DefaultArticleSection>
            <MultiImageSection title="So sieht eine dieser Container aus, nachdem wir ihn befüllt haben:" images={[require("../../../../assets/blog/articles/2023/aufraeumen-isarstrand/container-1.jpg"), require("../../../../assets/blog/articles/2023/aufraeumen-isarstrand/container-2.jpg")]}>
            </MultiImageSection>
        </Article>
    );
}

const RenderedArticle: BlogArticle = {
    ...MetaData,
    page: View
}

export default RenderedArticle; 
