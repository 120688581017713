import { ReactElement } from "react";

export interface BlogArticleMeta {
    title: string;
    date: Date;
    headerImage: string;
    headerImageAlt: string;
    description: string;
    tags: string[];
    url: string;
    authors: string[];
}

export interface BlogArticle extends BlogArticleMeta {
    page: () => ReactElement;
}

export enum BlogAuthor {
    DUSCHKA_FERDI = "Ferdinand Duschka",
    KAMINSKI_NIKOLAUS = "Nikolaus Kaminski",
    KAMINSKI_PHILIPP = "Philipp Kaminski"
}