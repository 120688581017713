import React from "react";
import PingHeader from "../PingHeader";
import Graphic from "../../assets/mitmachenHeader.svg";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <section className="header">
            <div className="container">
                <div className="info">
                    <PingHeader text="Ganz Einfach..." />
                    <h2 className="section-title">Bei uns Mitmachen</h2>
                    <p className="discription">
                        Jeder, der Spaß, Motivation und Freude an der Natur mitbringt ist bei uns immer herzlich willkommen! Um bei uns offiziell als Member anerkannt zu werden, muss man einfach den unten gezeigten Schritten folgen. Eine Liste aller bereits aktiven Isar-Ranger findet man unter dem <Link to="/about">Über uns</Link> Tab.
                        <br /><strong>Achtung: Man ist erst nach der ersten Teilnahme Mitglied!</strong>
                    </p>
                    <Link to={"/anmelden"} className="cta-mitmachen">Jetzt Mitmachen!</Link>
                </div>
                <img src={Graphic} alt="" />
            </div>
        </section>
    );
}

export default Header;