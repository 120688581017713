import React from "react";
import PingHeader from "../PingHeader";
import Agenda from "./Agenda";

const InfoSection = () => {
    return (
        <section className="info-section">
            <div className="section-content">
                <div className="info">
                    <PingHeader text="Wer wir sind..." />
                    <h2 className="section-title">Die IsarRanger<br />roots<span className="colored">&</span>shoots</h2>
                    <h3 className="subtitle">Jane Goodall Institut Deutschland</h3>
                    <p className="discription">
                        Die IsarRanger sind eine ehrenamtlich engagierte Gruppe aus Jugendlichen.
                        Organisiert sind sie im Jane Goodall Institut Deutschland.
                        Wir pflegen die Isar durch unterschiedlichste Wege um die Grosshesselohe-Brücke herum.
                        Dabei ist uns die Balance zwischen Spaß, Lernen und Arbeit am wichtigsten.
                    </p>
                </div>
                <Agenda />
            </div>
        </section>
    );
}

export default InfoSection;