import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.svg";
import Menu from '../assets/icons/menu.svg';

const Navbar = () => {

    const links = [
        { name: "Start", path: "/" },
        { name: "Projekte", path: "/projektbeschreibung" },
        { name: "Blog", path: "/blog" },
        { name: "Mitmachen", path: "/mitmachen" },
        { name: "Über uns", path: "/about" },
        // { name: "Kontakt", path: "/kontakt" },
    ]

    const translusentPages = ['/']
    let location = useLocation();
    const [scrolled, setScrolled] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        setExpanded(false);
        window.scrollTo(0, 0);
        if (!translusentPages.includes(window.location.pathname)) {
            setScrolled(true);
            document.onscroll = () => {}
        } else {
            setScrolled(false);

            document.onscroll = () => {
                if (window.scrollY > 20) {
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }
            }
        }
       
    }, [location])

    return (
        <nav className={`navbar ${(scrolled || expanded) && "scrolled"}`} style={{
        }}>
            <div className="container">
                <div className="static">
                    <Link className="logo" to="/">
                        <img src={Logo} alt="IsarRanger Logo" />
                        <h1>Isar<span className="colored">Ranger</span></h1>
                    </Link>
                    <button className="burgerMenu" onClick={() => setExpanded(state => !state)}>
                        <img src={Menu} alt="Menu" />
                    </button>
                </div>
                <ul className={`links ${expanded && 'expanded'}`}>
                    {links.map(link =>
                        <li>
                            <Link to={link.path} className={location.pathname === link.path ? 'active' : ''}>{link.name}</Link>
                        </li>
                    )}
                </ul>

            </div>

        </nav>
    )
}

export default Navbar;