import React from "react";
import MapGraphic from "../../assets/isarMap.png";
import PingHeader from "../PingHeader";

const Area = () => {
    return (
        <div className="ourArea">
            <div className="container">
                <div className="info">
                    <PingHeader text="Das ist..." />
                    <h2 className="title">Unser Einsatzgebiet</h2>
                    <div className="mobileMap">
                        <img src={MapGraphic} alt="Karte des Einsatzgebiets der IsarRanger" />
                    </div>
                    <p className="text">
                        Unser Motto: Go Local.
                        Um die Natur zu schützen müssen wir nicht direkt in weit entfernte Brennpunkt-Orte fahren, sondern können im kleinen bei uns in der Umgebung anfangen.
                        Deshalb erstreckt sich unser Einsatzgebiet über einen relativ überschaubaren Bereich rund um die Großhesseloher Brücke. Wir pflegen die Umgebung, in der wir selber gerne Feiern gehen und guten Zugang zu haben. Dies ermöglicht uns auch relativ viele Events (trotz Schul-Stress) zu organisieren und durchzuführen, die anderweitig nie möglich gewesen wären.                    </p>
                </div>
                <div className="map">
                    <img src={MapGraphic} alt="Karte des Einsatzgebiets der IsarRanger" />
                </div>
            </div>
        </div>
    );
}

export default Area; 