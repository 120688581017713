import React from "react";

const DefaultArticleSection: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {
    return(
        <section>
            <div className="container">
                {children}
            </div>
        </section>
    );
}

export default DefaultArticleSection;