import React from "react";
import '../styles/projektbeschreibung.scss';
import Header from "../components/projektbeschreibung/Header";
import Process from "../components/process/Process";
import { Helmet } from "react-helmet";


const Projektbeschreibung = () => {
    return (
        <div className="page projektbeschreibung">
            <Helmet>
                <title>Projekte | IsarRanger</title>
            </Helmet>
            <Header />
            <Process
                hilighted={false}
                title="Unkraut"
                tasks={[
                    {
                        title: "Unkraut aufsuchen",
                        description: "Zuerst muss das Unkraut und die Invasiven Pflanzen gefunden werden"
                    },
                    {
                        title: "Unkraut entfernen",
                        description: "Daraufhin wird dieses mit Gartenschere und ähnlichen Utensilien vorsichtig entfernt"
                    },
                    {
                        title: "Unkraut entsorgen",
                        description: "Die entfernten Pflanzen tragen immernoch Pollen und müssen deshalb sorgfältig entsorgt werden"
                    }
                ]}
            />
            <Process
                hilighted={true}
                title="Insekten"
                tasks={[
                    {
                        title: "Insektenhaus bauen",
                        description: "Pflanzenstängel können mit einer Dose in ein Insektenhaus verwandelt werden"
                    },
                    {
                        title: "Insektenhaus aufhängen",
                        description: "An bestimmten Bereichen an der Isar (oder privaten Gärten) werden diese befestigt"
                    },
                    {
                        title: "Probleme beobachten",
                        description: "Es können immer unerwartete Probleme auftreten, weshalb man die Häuser regelmäßig überprüfen sollte"
                    }
                ]}
            />
            <Process
                hilighted={false}
                title="Aufräumen"
                tasks={[
                    {
                        title: "Utensilien besorgen",
                        description: "Um sich ans Müll sammeln zu machen braucht man erst ein paar Tüten und eventuell Greifzangen"
                    },
                    {
                        title: "Müll aufheben",
                        description: "Daraufhin müssen die Massen an Müll gefunden und gesammelt werden"
                    },
                    {
                        title: "Müll entsorgen",
                        description: "Der Müll wird dann an bestimmten Stellen gesammelt und entsorgt/ Pfand zurück gegeben"
                    }
                ]}
            />
        </div>
    )
}

export default Projektbeschreibung;
