import React from "react";
import "../styles/about.scss"
import Header from "../components/about/Header";
import Stats from "../components/about/Stats";
import Organisation from "../components/about/Organisation";
import { Helmet } from "react-helmet";

const About: React.FC = () => {
    return (
        <div className="page about">
            <Helmet>
                <title>Über uns | IsarRanger</title>
            </Helmet>
            <Header />
            <div className="container">
                <Stats />
                <Organisation />
            </div>
        </div>
    );
}

export default About;