import React from "react";
import "../../styles/agenda.scss";
import UnkrautIcon from '../../assets/icons/unkraut.png';

const Agenda = ({ expandable = true }) => {

    const [expanded, setExpandedState] = React.useState(expandable ? 1 : 0);

    const setExpanded = (index: number) => { if (expandable) setExpandedState(index); }

    return (
        <div className="agenda">
            <AgendaEntry title="Unkraut" date="23.06" color="#a8a8a8"
                expanded={expanded === 1}
                desc="Unterschiedlichste Arten von Unkraut (z.B. japanischer Knöterich/ Indisches Springkraut/ ...) bedrohen die Artenvielfalt unserer Isar: Sie nehmen den heimischen Pflanzen das Sonnenlicht und die reichen Böden weg, die sie zum Überleben bräuchten."
                onClick={() => setExpanded(1)}
            />
            <AgendaEntry title="Aufräumen" date="04.07" color="#EC8A64"
                expanded={expanded === 2}
                desc="Das Isartal: ein optimaler Party-Ort für Jugendliche und Erwachsene. Dabei lassen aber viele ihren Müll einfach nach dem Feiern liegen, da „irgendjemand ihn schon mitnehmen wird”. Dies hat zur Folge, dass sich Müllberge anreichern, wogegen wir durch Aufräum- und Plakataktionen vorgehen wollen."
                onClick={() => setExpanded(2)}
            />
            <AgendaEntry title="Insekten" date="22.07" color="#7ABE9B"
                expanded={expanded === 3}
                desc="Jeder weiß, dass zu einem gesunden Öko-System nicht nur eine gesunde Flora, sondern auch eine angemessene Fauna gehören. Um dies zu gewährleisten bauen wir aus unterschiedlichsten Materialien (z.B. Unkraut-Stängel) einfache, aber effektive Insektenunterkünfte."
                onClick={() => setExpanded(3)}
            />
        </div>
    )
}

const AgendaEntry: React.FC<{
    title: string;
    date: string;
    color: string;
    desc?: string;
    icon?: string;
    expanded?: boolean;
    onClick?: () => void;
}> = ({ title, date, color, desc, icon, onClick, expanded }) => {
    return (
        <div className="entry" onClick={onClick}>
            <div className="bar">
                <div className="sec">
                    <div className="icon" style={{ backgroundColor: color }}>
                        {icon && <img src={icon} alt="icon" className="graphic" />}
                    </div>
                    <h4 className="title">{title}</h4>
                </div>
                {/* <p className="date">{date}</p> */}
            </div>
            {desc && <p className={`description ${(expanded || false) && 'expanded'}`}>{desc}</p>}
        </div>
    )
}

export default Agenda;