import React from "react";
import { Helmet } from "react-helmet";


const Anmelden: React.FC = () => {

    React.useEffect(() => {
        window.location.href = "https://forms.gle/a9rM6fyc8gJa9peM9";
    }, [])

    return (
        <div className="mitmachen">
            <Helmet>
                <title>Anmelden | IsarRanger</title>
            </Helmet>
        </div>
    );
}

export default Anmelden;