import React from "react";
import "../../../../styles/blogArticle.scss";
import { BlogArticle, BlogArticleMeta, BlogAuthor } from "../../../../@types/blog.types";
import HeaderImage from "../../../../assets/blog/headerImages/besuch-beim-imker.jpeg";
import {
    DefaultArticleSection,
    ImageSection,
    MultiImageSection
} from "../../../../components/blog/ArticleSection";
import Article from "../../../../components/blog/Article";

const MetaData: BlogArticleMeta = {
    title: "Besuch bei einem lokalen Imker",
    date: new Date(2023, 9, 4),
    headerImage: HeaderImage,
    headerImageAlt: "Unterwegs bei einem Imker und den faszinierenden Bienen",
    description: "Die IsarRanger besuchen einen lokalen Imker und seine Bienen",
    tags: ["Besuch", "Imker", "Bienen"],
    url: "/2023/besuch-beim-imker",
    authors: [BlogAuthor.KAMINSKI_NIKOLAUS, BlogAuthor.DUSCHKA_FERDI]
}

const View = () => {
    return (
        <Article meta={MetaData}>
            <DefaultArticleSection>
                <p>
                    Heute besuchten die IsarRanger einen Imker in Großhadern/München und hatten die Möglichkeit die erstaunliche Welt der Bienen aus nächster Nähe zu beobachten.
                </p>
            </DefaultArticleSection>
            <ImageSection imageLeft title="Eine Begegnung mit den Bienen" image={require("../../../../assets/blog/articles/2023/besuch-beim-imker/bienen-halten.jpeg")}>
                Unsere Gruppe traf sich am Bienenstock des Imkers. Dort wurden wir herzlich begrüßt und erhielten eine Einführung in das Leben der Bienen. Ohne Schutzkleidung konnten wir die Bienen aus nächster Nähe beobachten, während sie herumschwirrten und den Bienenstock schützten.
            </ImageSection>
            <ImageSection title="Hier nochmal ein Fun-Fact über Bienen!" image={require("../../../../assets/blog/articles/2023/besuch-beim-imker/closeup.jpeg")}>
                Der Bienentanz als Kommunikationsmittel: Bienen verwenden spezielle Tanzbewegungen, um ihren Artgenossen den genauen Standort von Blumen und Nahrungsquellen mitzuteilen. Dieser "Bienentanz" ist ein bemerkenswertes Beispiel für ihre intelligente Kommunikation.
            </ImageSection>
            <MultiImageSection images={[require("../../../../assets/blog/articles/2023/besuch-beim-imker/01.jpeg"), require("../../../../assets/blog/articles/2023/besuch-beim-imker/02.jpeg"), require("../../../../assets/blog/articles/2023/besuch-beim-imker/03.jpeg")]}>
                Unser Tag bei diesem Imker hat uns einen tieferen Einblick in die Welt der Bienen gewährt und verdeutlicht, wie wichtig es ist, diese fleißigen Insekten zu schützen und zu unterstützen.
            </MultiImageSection>
        </Article>
    );
}

const RenderedArticle: BlogArticle = {
    ...MetaData,
    page: View
}

export default RenderedArticle; 
