import React from "react";
import { Helmet } from "react-helmet";
import { BlogArticleMeta } from "../../@types/blog.types";

const ArticleHeader: React.FC<{
    MetaData: BlogArticleMeta
}> = ({MetaData}) => {
    return (
        <>
            <Helmet>
                <title>{MetaData.title} | IsarRanger</title>
                <meta property="og:image" content={MetaData.headerImage} />
            </Helmet>
            <div className="container">
                <img src={MetaData.headerImage} alt="Header Image" className="headerImage" />
                <p className="headerImageAlt">{MetaData.headerImageAlt}</p>
                <h1 className="articleTitle">{MetaData.title}</h1>
                <p className="meta">{MetaData.authors.toString().replace(",", " & ")}, {MetaData.date.toLocaleDateString("de-DE", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric"
                })}</p>
            </div>
        </>
    );
}

export default ArticleHeader;