import Graphic from '../../assets/landingShow.webp';
import GraphicMin from '../../assets/landingShowMin.webp';

const ImageShow = () => {
    return (
        <div className="imageShow">
            <picture>
                <source type="image/webp" srcSet={Graphic} media="(min-width: 768px)" />
                <source type="image/webp" srcSet={GraphicMin} media="(max-width: 767px)" />
                <img src={GraphicMin} alt="" className="graphic" loading="lazy" />
            </picture>
        </div>
    );
}

export default ImageShow;