import React from "react";
import "../../../../styles/blogArticle.scss";
import { BlogArticle, BlogArticleMeta, BlogAuthor } from "../../../../@types/blog.types";
import HeaderImage from "../../../../assets/blog/headerImages/day-of-hope.jpeg";
import {
    DefaultArticleSection,
    ImageSection,
    MultiImageSection
} from "../../../../components/blog/ArticleSection";
import Article from "../../../../components/blog/Article";

const MetaData: BlogArticleMeta = {
    title: "Ein Tag der Hoffnung",
    date: new Date(2023, 4, 30),
    headerImage: HeaderImage,
    headerImageAlt: "Boden-Graffiti beim Day of Hope",
    description: "Day of Hope: Inspiration, Engagement und prominente Unterstützung für die Umwelt.",
    tags: ["Day of Hope", "Jane Goodall", "2023"],
    url: "/2023/day-of-hope",
    authors: [BlogAuthor.DUSCHKA_FERDI, BlogAuthor.KAMINSKI_PHILIPP]
}

const View = () => {
    return (
        <Article meta={MetaData}>
            <DefaultArticleSection>
                <p>
                    Am 4. Mai 2023 haben wir bei dem Stand der Würm Ranger beim Day of Hope mitgeholfen. Von unseren Erfahrungen, die wir bei diesem von der Otto Eckart Stiftung in Zusammenarbeit mit dem Jane Goodall Institut und vielen weiteren Partnern organisierten Tag gesammelt haben, werden wir im folgenden Blogbeitrag berichten.
                </p>
            </DefaultArticleSection>
            <ImageSection title="Unterstützung am Stand der Würmranger" image={require("../../../../assets/blog/articles/2023/day-of-hope-2023/stand.jpeg")}>
                Der Stand der Würmranger präsentierte viele verschiedene Projekte an denen sich die Besucher inspirieren konnten.
                Ein Beispiel dafür sind die selbst gebastelten Aschenbecher, die unter anderem aus alten Milchkartons gemacht werden. Unsere Aufgabe war es immer mal wieder den Stand zu übernehmen, damit jeder den Tag genießen und sich auch andere Projekte anschauen konnte.
            </ImageSection>
            <MultiImageSection title="Begegnung mit einer Ikone - Jane Goodall" images={[
                require("../../../../assets/blog/articles/2023/day-of-hope-2023/jane-2.jpeg"),
                require("../../../../assets/blog/articles/2023/day-of-hope-2023/jane-1.jpeg"),
                require("../../../../assets/blog/articles/2023/day-of-hope-2023/jane-0.jpeg"),
            ]}>
                Ein unvergesslicher Moment war für uns Jane Goodall in Person zu sehen und nicht immer nur auf dem Bildschirm. Sie hielt eine sehr inspirierende Rede und beeindruckte uns, wie auch die anderen Zuschauer, mit ihrer über Jahre hinweg gesammelten Erfahrung im Thema des Naturschutzes.
            </MultiImageSection>
            <MultiImageSection title="Vielfalt des Engagements - Projekte und Ideen am Day of Hope" images={[
                require("../../../../assets/blog/articles/2023/day-of-hope-2023/projekte-1.jpeg"),
                require("../../../../assets/blog/articles/2023/day-of-hope-2023/projekte-0.jpeg"),
            ]}>
                Der Day of Hope bot auch anderen Ständen und Organisationen eine Plattform, ihre Projekte und Ideen vorzustellen. Es war faszinierend zu sehen, wie vielfältig das Engagement der Teilnehmer war. Ein Stand präsentierte innovative Ideen zur nachhaltigen Energiegewinnung, während ein anderer über die Bedeutung des Artenschutzes aufklärte. Es gab auch Stände, die sich für die Förderung der Umweltbildung einsetzten und den Besuchern spannende Aktivitäten anboten
            </MultiImageSection>
            <ImageSection title="Prominente Unterstützung für den Schutz unserer Umwelt" image={require("../../../../assets/blog/articles/2023/day-of-hope-2023/neuer.jpeg")}>
                Neben den zahlreichen engagierten Jugendlichen und Erwachsenen, die den Day of Hope besuchten, wurden wir auch von prominenten Gästen überrascht. Fußball-Nationaltorhüter Manuel Neuer und der bayerische Ministerpräsident Markus Söder waren unter den Besuchern, die ihre Unterstützung für die gute Sache zeigten.
            </ImageSection>
            <DefaultArticleSection>
                <p>
                    Der Day of Hope war ein Tag voller Inspiration, Engagement und Hoffnung. Er zeigte uns, dass jeder von uns die Möglichkeit hat, etwas Gutes zu bewirken und dass wir gemeinsam eine positive Veränderung erreichen können. Wir sind dankbar, Teil dieser bedeutenden Veranstaltung gewesen zu sein und freuen uns darauf, unsere Projekte und Ideen weiterhin voranzutreiben
                </p>
            </DefaultArticleSection>
        </Article>
    );
}

const RenderedArticle: BlogArticle = {
    ...MetaData,
    page: View
}

export default RenderedArticle; 
