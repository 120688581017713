import React from "react";
import "../../styles/process.scss";
import { TaskProps, Task } from "./Task";

interface ProcessProps {
    hilighted: boolean
    title: string
    tasks: TaskProps[]
}

const Process: React.FC<ProcessProps> = ({ hilighted, title, tasks }) => {
    return (
        <div className={`process ${hilighted && 'hilighted'}`}>
            <div className="container">
                <h3 className="title">{title}</h3>
                <div className="tasks">
                    {tasks.map((task, index) =>
                        <>
                            <Task key={index} data={{
                                index: index + 1,
                                title: task.title,
                                description: task.description
                            }} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Process;