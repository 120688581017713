import React from "react";
import "../../../../styles/blogArticle.scss";
import { BlogArticle, BlogArticleMeta, BlogAuthor } from "../../../../@types/blog.types";
import HeaderImage from "../../../../assets/blog/headerImages/wuermranger-japan-knoeterich.jpeg";
import {
    DefaultArticleSection,
    ImageSection,
    MultiImageSection
} from "../../../../components/blog/ArticleSection";
import Article from "../../../../components/blog/Article";

const MetaData: BlogArticleMeta = {
    title: "Lernen von den Profis",
    date: new Date(2022, 6, 22),
    headerImage: HeaderImage,
    headerImageAlt: "Eine kurze Einweisung ins Knöterich-Schneiden",
    description: "Heute haben die Würmranger uns das Eindämmen des Japan Knöterich beigebracht!",
    tags: ["Wuermranger", "Japan", "Knoeterich"],
    url: "/2022/wuermranger-japan-knoeterich",
    authors: [BlogAuthor.KAMINSKI_NIKOLAUS]
}

const View = () => {
    return (
        <Article meta={MetaData}>
            <DefaultArticleSection>
                <p>
                    Heute haben die Würmranger uns das Eindämmen des Japanknöterich beigebracht! Dieses Event ist auch das erste offizielle Treffen der Isar Ranger. Durch das unglaubliche Wissen, das die Würmranger durch ihre Jahrelange Erfahrung angesammelt haben und jetzt an uns weiter gegeben haben, fühlen wir uns bereit unseren Weg als Isar Ranger einzuschlagen! Auch an der physischen Arbeit haben die Würm Ranger uns Teil haben lassen, ein großartiger erster Schritt zur ersten Unkraut-Eindämmung an der Isar!
                    Anfangs haben wir den jungen Würm Rangern beim schneiden zuschauen dürfen und die Basics des Knöterich-Schneiden gelernt!
                    Daraufhin sind wir die drei Schritte des Unkraut-Entfernens durchgegangen:
                </p>
            </DefaultArticleSection>
            <ImageSection title="Knöterich aufsuchen" image={require("../../../../assets/blog/articles/2022/wuermranger-japan-knoeterich/japan-knoeterich.jpg")}>
                Um den Japanknöterich eindämmen zu können muss man erstmal herausfinden, wie dieser aussieht: (siehe Bild)                                                                                                                                   Diesen an der Würm zu finden war ein leichtes - Überall wo man hinschaut verbirgt sich eine Pflanze und behindert die einheimische Flora. Den Knöterich gibt es dabei auch in allen größen, von einem Mini-Busch bis hinzu halben Bäumen.
                Nachdem wir einige Knöterichs gefunden haben und bei der Entfernung von diesen zuschauen konnten, war es nun an der Zeit für uns uns selber an die Arbeit zu machen und das herausschneiden auszuprobieren.
            </ImageSection>
            <MultiImageSection title="Knöterich entfernen" images={[
                require("../../../../assets/blog/articles/2022/wuermranger-japan-knoeterich/knoeterich-entfernen-1.jpg"),
                require("../../../../assets/blog/articles/2022/wuermranger-japan-knoeterich/knoeterich-entfernen-2.jpg"),
            ]}>
                Weil der Knöterich unglaublich tiefe Wurzeln schlägt, kann man diesen nur abschneiden, anstatt ihn ein für alle Male zu entfernen. Eine wahre Sisyphos-Arbeit, wenn man bedenkt, dass die Pflanze unter optimalen Bedingungen bis zu 30cm am Tag wachsen kann! Deshalb sind regelmäßige Treffen, wie die Würmranger sie veranstalten, essenziell zur Bekämpfung dieser invasiven Spezies.
                Das Schneiden der Knöterichs ist sehr simpel: einfach mit der Gartenschere so viel wie möglich entfernen! <br /> <br />
                Das klingt zwar sehr leicht, doch nach einer Weile kann auch solch eine simple Aufgabe anstrengend werden, besonders wenn sich der Knöterich an ungünstigen Stellen befindet.
            </MultiImageSection>
            <ImageSection title="Kurze Pause - Die Würm genießen" image={require("../../../../assets/blog/articles/2022/wuermranger-japan-knoeterich/wuerm-genießen.jpg")}>
                Bevor es ans Aufräumen geht, zeigen uns die Würmranger noch einen ehemaligen Geheimplatz, der seit Corona leider nicht mehr so geheim ist. Deshalb konnte man sehen, wie sich der Müll langsam ansammelte, wogegen die Ranger aber bereits Vorkehrungen, wie selbst gebaute Mülleimer, getroffen haben. Dadurch sieht der Platz immernoch relativ idyllisch aus 😃
            </ImageSection>
            <MultiImageSection title="Knöterich entsorgen" images={[
                require("../../../../assets/blog/articles/2022/wuermranger-japan-knoeterich/knoeterich-entsorgen-0.jpg"),
                require("../../../../assets/blog/articles/2022/wuermranger-japan-knoeterich/knoeterich-entsorgen-1.jpg"),
                require("../../../../assets/blog/articles/2022/wuermranger-japan-knoeterich/knoeterich-entsorgen-2.jpg"),
            ]}>
                Beim entsorgen ist besondere Vorsicht geboten: es dürfen keine Samen/ Pollen ausversehen verloren gehen und dadurch eine weitere Wiese befallen! Das heißt auch, dass man die Überreste nicht einfach in den Biomüll geben darf, da er von dort kompostiert werden würde und sich die Pollen so über ganz Deutschland ausbreiten könnten! <br />
                Deshalb sollte man das Wegbringen den Profis überlassen. Man bringt die Stapel an Japanknöterichs an einen Straßenrand und kontaktiert die Gemeinde, dass diese den Knöterich verbrennen lassen. Leichter gesagt als getan - Die unmengen an Abfall transportieren sich nicht von alleine!
            </MultiImageSection>
        </Article>
    );
}

const RenderedArticle: BlogArticle = {
    ...MetaData,
    page: View
}

export default RenderedArticle; 
