import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Landing from './views/Landing';
import Projektbeschreibung from './views/Projektbeschreibung';
import About from './views/About';
import Mitmachen from './views/Mitmachen';
import Datenschutz from './views/Datenschutz';
import Impressum from './views/Impressum';
import Blog from './views/blog/Blog';
import NotFound from './views/404';
import { Articles } from './views/blog/handler';
import Anmelden from './views/Anmelden';

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/projektbeschreibung" element={<Projektbeschreibung />} />
          <Route path="/about" element={<About />} />
          <Route path="/mitmachen" element={<Mitmachen /> } />
          <Route path="/anmelden" element={<Anmelden /> } />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/blog" element={<Blog />} />
          {Articles.map((article, index) =>
            <Route path={`/blog${article.url}`} element={<article.page />} key={index} />
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
