import React from "react";
import ArticleSection from "./DefaultArticleSection";

const ImageSection: React.FC<{
    image: string
    children: React.ReactNode
    title: string
    imageLeft?: boolean
}> = ({ children, title, image, imageLeft }) => {
    return(
        <ArticleSection>
            <div className="imageSection">
                {imageLeft &&
                    <img src={image} alt={title} />
                }
                <div className="info">
                    <h2 className="sectionTitle">{title}</h2>
                    <p>{children}</p>
                </div>
                {!imageLeft &&
                    <img src={image} alt={title} />
                }
            </div>
        </ArticleSection>
    );
}

export default ImageSection;